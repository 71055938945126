
import { fetchParkTree } from '@/api/index'
import { shortMap } from '@/pages/Dashboard/widgets/ShortcutManage/config'
export default {
  namespaced: true,

  state: {
    parkingData: [], // 停车场数据
    authShortMap: shortMap // 用户有权限查看的快捷入口
  },

  actions: {
    // 获取停车场数据
    async getParkingData ({ commit }) {
      const { code, data } = await fetchParkTree({ types: ['parking', 'subPark'] })
      if (code === '0') {
        data.forEach(item => {
          item.value = item.id
        })
        data.unshift({
          name: '全部停车库',
          value: ''
        })
        commit('setParkingData', data || [])
      }
    }
  },

  mutations: {
    setParkingData (state, playload) {
      state.parkingData = playload
    },
    setAuthShortMap (state, playload) {
      state.authShortMap = playload
    }
  },

  getters: {
    parkingData (state) {
      return state.parkingData
    },
    authShortMap (state) {
      return state.authShortMap
    }
  }
}
