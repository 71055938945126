import { createCORSBus } from '@cors-bus/core'
import store from '@/store/index'

export const cbus = createCORSBus('portal')

cbus.request.control('getBreadcrumb', () => {
  return store.getters['navigation/currentBreadcrumb']
})

cbus.request.control('getToken', () => {
  return localStorage.tk
})

cbus.request.control('login', () => {
  window.location.href = '/login'
})

// pms项目中首次添加停车库页面中点击返回按钮触发的事件
cbus.request.control('back', () => {
  window.history.back()
})
