<template>
    <div class="eits-login">
        <h3 style="font-size: 20px;">欢迎使用</h3>
        <div class="info-wrap">
            <div class="login-info" v-show="showInfo">
                <svg t="1568102445422" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4470" width="16" height="16"><path d="M512.00512 66.56C265.98912 66.56 66.56 265.98912 66.56 512c0 246.016 199.43424 445.44 445.44512 445.44 246.016 0 445.43488-199.42912 445.43488-445.44 0-246.00576-199.42912-445.44-445.43488-445.44z m216.09472 606.40768a38.7584 38.7584 0 0 1 11.42784 27.59168 38.76864 38.76864 0 0 1-11.42784 27.60192 38.79424 38.79424 0 0 1-27.60192 11.43808 38.79936 38.79936 0 0 1-27.5968-11.43808l-158.44352-158.4384-158.4384 158.4384c-7.3728 7.36768-17.17248 11.43808-27.60192 11.43808s-20.224-4.0704-27.5968-11.43808a38.7584 38.7584 0 0 1-11.43296-27.59168c0-10.42944 4.06016-20.22912 11.43296-27.60192L459.264 514.52416 300.8256 356.08064c-7.3728-7.36768-11.43296-17.17248-11.43296-27.60192s4.06016-20.224 11.43296-27.60192a38.76864 38.76864 0 0 1 27.59168-11.42272c10.42944 0 20.22912 4.05504 27.60192 11.42272l158.44352 158.44352 158.44352-158.44352a38.77376 38.77376 0 0 1 27.5968-11.42272c10.42944 0 20.22912 4.05504 27.60192 11.42272 7.3728 7.3728 11.42784 17.17248 11.42784 27.60192s-4.06528 20.22912-11.42784 27.60192l-158.4384 158.4384 158.43328 158.44864z" fill="#FE5332" p-id="4471"></path></svg>
                <span class="info-text">{{ errMsg }}</span>
            </div>
        </div>

        <div class="login-user" :style="{'margin-bottom':`${margin}px`}">
            <el-input v-model="user" size="large" prefix-icon="h-icon-user" :placeholder="userPlaceHolder" @blur="getVerifyCode(true)" @keyup.enter.native="beforeLogin"></el-input>
        </div>

        <div class="login-pwd" :style="{'margin-bottom':`${margin}px`}">
            <el-input v-model="password" size="large" prefix-icon="h-icon-lock" :type="passwordType" :placeholder="pwdPlaceHolder" @keyup.enter.native="beforeLogin">
                <!-- <i
                    slot="suffix"
                    class="el-input__icon"
                    :class="passwordIcon"
                    @mousedown="onMousedownClick"
                    @mouseup="onMouseupClick">
                </i> -->
            </el-input>
        </div>

        <div class="login-check" :style="{'margin-bottom':`${margin/2}px`}" v-if="isCheck">
            <eits-slide ref="dragVerify" @slide-check="slideCheck"></eits-slide>
        </div>

        <div class="login-option" :style="{'margin-bottom':`${margin*2}px`}">
            <eits-checkbox v-model="isKeep" @change="changeKeep">记住用户名</eits-checkbox>
            <el-button type="link" @click="forgetPwd">忘记密码</el-button>
        </div>

        <div class="login-btn">
            <el-button type="primary" class="btn-inner" @click="beforeLogin" :loading="loading">登录</el-button>
        </div>

        <eits-login-pwd-dialog
            @check-back="checkBack"
            @closeDialog="closeDialog"
            :user="user"
            :productCode="productCode"
            :token="token"
            :type=1
            :changePwd="changePwd"
            :changePhone="changePhone"
            :dialogTitle="dialogTitle"
            :showDialog="showDialog"
            :challengeCodeUrl="challengeCodeUrl"
            :phoneNoVerifyUrl="phoneNoVerifyUrl"
            :changeWeakPwdUrl="changeWeakPwdUrl"
            :getVerifyUrl="getVerifyUrl"
            :checkVerifyUrl="checkVerifyUrl">
        </eits-login-pwd-dialog>
    </div>
</template>

<script>
import { USER_IS_EMPTY, PWD_IS_EMPTY, ID_IS_EMPTY, USER_PWD_ERROR, SLIDE_CHECK_ERROR } from './constant.js'
import { pwdHashEncrypt } from '@/utils/util.js'
import axios from 'axios'
export default {
  name: 'eits-login',
  data () {
    return {
      user: '',
      password: '',
      passwordType: 'password',
      verifyCodeId: '',
      isKeep: false,
      isCheck: false,
      errMsg: '',
      showInfo: false,
      passwordIcon: 'h-icon-password_unvisible',
      slideCheckResult: false,
      loading: false,
      token: '',
      changePwd: false,
      changePhone: false,
      dialogTitle: '',
      showDialog: false
    }
  },
  props: {
    isLocalStorage: {
      type: Boolean,
      default: true
    },
    /**
         * [margin input上下间距]
         * @type {Number}
         */
    margin: {
      type: Number,
      default: 16
    },
    /**
         * [userPlaceHolder 第一个输入框的placeholder]
         * @type {Object}
         */
    userPlaceHolder: {
      type: String,
      default: '用户名'
    },
    /**
         * [pwdPlaceHolder 第二个输入框的placeholder]
         * @type {Object}
         */
    pwdPlaceHolder: {
      type: String,
      default: '密码'
    },
    productCode: {
      type: String,
      default: '',
      required: true
    },
    /**
         * [userIsEmptyMsg 用户名为空提示语]
         * @type {Object}
         */
    userIsEmptyMsg: {
      type: String,
      default: USER_IS_EMPTY
    },
    /**
         * [pwdIsEmptyMsg 密码为空提示语]
         * @type {Object}
         */
    pwdIsEmptyMsg: {
      type: String,
      default: PWD_IS_EMPTY
    },
    /**
         * [idIsEmptyMsg 产品标识为空提示语]
         * @type {Object}
         */
    idIsEmptyMsg: {
      type: String,
      default: ID_IS_EMPTY
    },
    /**
         * [userPwdErrorMsg 用户名或密码错误提示语]
         * @type {Object}
         */
    userPwdErrorMsg: {
      type: String,
      default: USER_PWD_ERROR
    },
    /**
         * [verifyCodeUrl 获取活动检测接口]
         * @type {String}
         */
    verifyCodeUrl: {
      type: String,
      default: '/eits/login/verifyCode'
    },
    /**
         * [challengeCodeUrll 获取挑战码接口]
         * @type {String}
         */
    challengeCodeUrl: {
      type: String,
      default: '/eits/login/challengeCode'
    },
    /**
         * [loginUrl 登录接口]
         * @type {Object}
         */
    loginUrl: {
      type: String,
      default: '/eits/login'
    },
    /**
         * [changeWeakPwdUrl 修改密码接口]
         * @type {Object}
         */
    changeWeakPwdUrl: {
      type: String,
      default: '/eits/login/user/pwd/update'
    },
    /**
         * [phoneNoVerifyUrl 手机认证状态接口]
         * @type {Object}
         */
    phoneNoVerifyUrl: {
      type: String,
      default: '/eits/login/phone/status'
    },
    /**
         * [getVerifyUrl 获取验证码接口]
         * @type {Object}
         */
    getVerifyUrl: {
      type: String,
      default: '/eits/login/user/phone/verifyCode'
    },
    /**
         * [checkVerifyUrl 校验验证码接口]
         * @type {Object}
         */
    checkVerifyUrl: {
      type: String,
      default: '/eits/login/user/phone/checkCode'
    }
  },
  created () {
    this.getMemory()
  },
  mounted () {
    this.getVerifyCode()
  },
  methods: {
    closeDialog () {
      this.changePwd = false
      this.changePhone = false
      this.showDialog = false
    },
    onMousedownClick (e) {
      this.passwordType = 'text'
      this.passwordIcon = 'h-icon-password_visible'
    },
    onMouseupClick (e) {
      this.passwordType = 'password'
      this.passwordIcon = 'h-icon-password_unvisible'
    },
    slideCheck (bool) {
      this.slideCheckResult = bool
    },
    checkEmpty (type, needCode = true) {
      this.showInfo = false
      this.errMsg = ''
      const temp = {
        user: this.userIsEmptyMsg,
        password: this.pwdIsEmptyMsg,
        productCode: ID_IS_EMPTY
      }
      const check = []
      if (type) check.push(type)
      else check.push('productCode', 'password', 'user')
      check.forEach((val, index) => {
        // eslint-disable-next-line no-unused-expressions
        this[val] ? '' : this.errMsg = temp[val]
      })
      if (this.isCheck && !this.slideCheckResult) this.errMsg = SLIDE_CHECK_ERROR
      if (this.user && this.productCode && needCode) this.getVerifyCode()
      this.showInfo = !!this.errMsg
    },
    getVerifyCode (clearMsg) {
      if (this.user) {
        axios({
          method: 'post',
          url: this.verifyCodeUrl,
          data: {
            name: this.user,
            productCode: this.productCode,
            type: 1
          }
        })
          .then((res) => {
            res = res.data
            // 用户被锁住
            if (res.code === '1015') {
              this.errMsg = res.msg
              this.showInfo = true
              this.isCheck = false
            } else if (res.code === '200') {
              // data有值表示需要滑动检测
              if (res.data) {
                this.isCheck = true
                this.$nextTick(() => {
                  this.$refs.dragVerify.reset()
                })
                this.verifyCodeId = res.data.verifyCodeId
              } else {
                if (clearMsg) {
                  this.errMsg = ''
                  this.showInfo = false
                }
                this.verifyCodeId = ''
              }
            } else if (res.code === '400') {
              // 替换错误提示中的“name”字样为“账号”
              this.errMsg = res.msg.replace('name', '账号')
              this.showInfo = true
            } else {
              // 其他错误情况
              this.errMsg = res.msg
              this.showInfo = true
              this.isCheck = false
            }
          }).catch((err) => {
            this.$message.error(err.message)
          })
      }
    },
    getChangeCode () {
      return axios({
        method: 'post',
        url: this.challengeCodeUrl,
        data: {
          name: this.user,
          productCode: this.productCode,
          type: 1
        }
      })
    },
    doLogin (hashPwd, codeId, verifyCodeId) {
      return axios({
        method: 'post',
        url: this.loginUrl,
        data: {
          codeId: codeId,
          name: this.user,
          password: hashPwd,
          productCode: this.productCode,
          type: 1,
          verifyCodeId: verifyCodeId
        }
      })
    },
    afterGetChangeCode (res) {
      if (res.data.data) {
        const { pwdStatus, codeId, salt, vCode } = res.data.data
        return { pwdStatus, codeId, salt, vCode }
      }
      if (res.data.code === '1014') {
        this.errMsg = this.userPwdErrorMsg
        this.password = ''
      } else {
        this.errMsg = res.data.msg
      }
      this.showInfo = !res.data.data
      return {}
    },
    async handleLogin () {
      try {
        this.loading = true
        const res = await this.getChangeCode()
        const { codeId, salt, vCode } = this.afterGetChangeCode(res)
        if (res.data.code === '200') {
          const hashPwd = pwdHashEncrypt(this.password, salt, vCode)
          const res = await this.doLogin(hashPwd, codeId, this.verifyCodeId)
          return res.data
        } else {
          this.loading = false
        }
      } catch (err) {
        this.$message.error(err.message)
        console.log(err)
        this.loading = false
      }
    },
    beforeLogin () {
      this.checkEmpty(null, false)
      // eslint-disable-next-line no-unused-expressions
      !this.showInfo ? this.handleLogin().then((res) => this.afterLogin(res)) : ''
    },
    afterLogin (res) {
      if (!res) { return }
      this.loading = false
      switch (res.code) {
      case '1013':
      case '1014':
        this.errMsg = this.userPwdErrorMsg
        this.showInfo = true
        this.getVerifyCode()
        break
      case '1015':
        this.errMsg = res.msg
        this.isCheck = false
        this.verifyCodeId = ''
        break
      case '1017':
      case '01060006':
      case '01080016':
        this.errMsg = res.msg
        break
      case '1016':
      case '1018':
        this.changePwd = true
        this.dialogTitle = '修改密码'
        this.showDialog = true
        this.token = res.data.Authorization
        this.memory()
        // this.loginBack(res);
        break
      case '1021':
        this.changePhone = true
        this.dialogTitle = '验证手机号'
        this.showDialog = true
        this.token = res.data.Authorization
        // this.loginBack(res);
        break
      case '1022':
        this.changePwd = true
        this.changePhone = true
        this.dialogTitle = '修改密码'
        this.showDialog = true
        this.token = res.data.Authorization
        // this.loginBack(res);
        break
      case '200':
        this.memory()
        this.loginBack(res)
        break
      case '400':
        this.$refs.dragVerify.reset()
        this.verifyCodeId = res.data.verifyCodeId
      // eslint-disable-next-line no-fallthrough
      default:
        this.errMsg = res.msg
        break
      }
      this.password = ''
      this.showInfo = !!this.errMsg
    },
    memory () {
      if (this.isLocalStorage) {
        if (this.isKeep) {
          localStorage.setItem('eits-login-user', this.user) // 记录用户本次登录成功的账号
        }
      } else {

      }
    },
    getMemory () {
      if (this.isLocalStorage) {
        this.user = localStorage.getItem('eits-login-user')
        this.isKeep = !!localStorage.getItem('eits-login-user')
      } else {

      }
    },
    changeKeep (val) {
      if (this.isLocalStorage) {
        // eslint-disable-next-line no-unused-expressions
        !val ? localStorage.setItem('eits-login-user', '') : ''
      } else {

      }
    },
    /** ***********对外事件VIP*************/
    forgetPwd () {
      // this.$emit('forget-pwd')
      // this.$router.push('portal/resetPwd')
      location.href = '/portal/resetPwd'
    },
    checkBack (payload) {
      this.$emit('check-back', payload)
    },
    loginBack (payload) {
      this.$emit('login-back', payload)
    }
    /**********************************/
  }
}

</script>

<style lang="scss">
    .eits-login {
        padding: 32px 40px 56px;
        position: relative;
        height: calc(100%);
        width: calc(100%);
        min-width: 160px;
        .login-user,
        .login-pwd,
        .login-id,
        .login-check,
        .login-btn {
            input {
                height: 44px;
            }
            position: relative;
            width: 100%;
            // height: 44px;
        }
        .el-input__prefix {
          top: 10px;
        }
        .login-pwd .el-input__suffix {
            top: 10px;
        }
        .info-wrap {
            position: relative;
            width: 100%;
            height: 20px;
        }
        .login-info {
            position: relative;
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .info-text {
                font-size: 14px;
                color: #999999;
            }
            svg {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }

        }
        .login-option {
            position: relative;
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 100%;
            margin-bottom: 24px !important;
        }
        .login-btn {
            .btn-inner {
                height: 44px;
                width: 100%;
                max-width: 500px !important;
            }
        }
        .login-id-hide {
            display: none;
        }
    }
</style>
