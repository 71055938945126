export const PWD_IS_EMPTY = '请输入新密码'

export const PWD_SIZE_RULE = '密码长度为8位~20位'

export const PWD_TYPE_RULE = '至少包含以下2种字符：大小写字母、数字、特殊字符'

export const CONFIRM_IS_EMPTY = '请再次输入新密码'

export const NOT_EQUAL_ERROR = '两次输入密码不一致'

export const PWD_CHAR_ERROR = '密码只能包含数字字母特殊字符'

export const PWD_SIZE_ERROR = '请输入8位~20位密码'

export const PWD_CHANGE_SUCCESS = '密码修改成功,请重新登录'

export const PHONE_EMPTY_ERROR = '请输入手机号'

export const PHONE_TYPE_ERROR = '请输入正确的手机号'

export const VERITIFY_EMPTY_ERROR = '请输入验证码'
