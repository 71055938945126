import Vue from 'vue'
import Login from './eits/login'
import LoginPwdDialog from './eits/login-pwd-dialog'
import Checkbox from './eits/checkbox'
import Slide from './eits/slide'
import PwdLevel from './eits/pwd-level'

Vue.use(Login)
Vue.use(LoginPwdDialog)
Vue.use(Checkbox)
Vue.use(Slide)
Vue.use(PwdLevel)
