<template>
  <div class="portal-wrap">
    <h-page id="app">
      <h-page-menu :menu="menu" />
      <router-view />
    </h-page>
    <div class="archival-information">
      © 2023 杭州海康威视数字技术股份有限公司 |
      <a class="record-link" style="margin: 0 4px;" @click="goIcp">蜀ICP备2022026707号-1</a>
      |
      <i class="icon-police" />
      <span class="record-link" @click="goRecord">浙公网安备 {{recordCode}}号</span>
    </div>
  </div>
</template>

<script>
import navList from './nav.config.js'

export default {
  name: 'App',
  data () {
    return {
      recordCode: '33010802011157'
    }
  },
  computed: {
    menu () {
      navList.forEach(nav => {
        nav.title = this.$t ? this.$t(nav.title) : nav.title
      })
      const { userInfo } = this.$store.state
      if (!userInfo) return []
      // 展示有权限的菜单项
      return navList.filter(
        nav => {
          return (userInfo.code || []).includes(`${process.env.VUE_APP_CONTEXT}_${nav.menuCode}`)
        }
      )
    }
  },

  mounted () {
    window.addEventListener('storage', (e) => {
      if (e.key === 'tk' && e.newValue == null) {
        this.$router.push('/login')
      }
    })
  },
  methods: {
    goRecord () {
      window.open(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${this.recordCode}`)
    },
    goIcp () {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss" scoped>
.portal-wrap {
  height: 100vh;
  width: 100vw;
  #app {
    height: calc(100vh - 32px);
  }
  .archival-information {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 32px;
    text-align: center;
    background: #f5f7f9;
    .icon-police {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url("./assets/images/police.png") no-repeat 100%;
      margin: 0 4px 0 0;
    }
  }
}
</style>
