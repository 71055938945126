import CryptoJS from 'crypto-js'
import Vue from 'vue'

/**
 * SHA256加密方法，用于验证密码
 * @param password 密码
 * @param salt 加密的盐
 * @param vCode 挑战码
 * @returns {String}  加密后密码
 */
export const pwdHashEncrypt = (password, salt, vCode) => {
  return CryptoJS.SHA256(CryptoJS.SHA256(password + salt).toString() + vCode).toString()
}

/**
 * 用户密码AES加密方法
 * @param password 密码
 * @param aesKey 密钥（可传用户名）
 * @returns {String}  加密后密码
 */
export const pwdAesEncrypt = (password, aesKey, salt) => {
  let key = CryptoJS.SHA256(CryptoJS.SHA256(aesKey).toString() + salt).toString().substr(0, 16)
  let vi = CryptoJS.SHA256(key).toString().substr(0, 16)
  key = CryptoJS.enc.Utf8.parse(key)
  vi = CryptoJS.enc.Utf8.parse(vi)
  const encryptedPwd = CryptoJS.AES.encrypt(password, key, { iv: vi, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.ZeroPadding }).toString()
  return encryptedPwd
}

/**
 * 通用用户密码SHA256加密方法，用于保存密码
 * @param password 密码
 * @param salt 加密的盐
 * @returns {String}  加密后密码
 */
export const pwdHashEncryptSave = (password, salt) => {
  return CryptoJS.SHA256(password + salt).toString()
}

/**
 * 通用用户密码SHA256加密方法，用于验证密码
 * @param password 密码
 * @param salt 加密的盐
 * @param vCode 挑战码
 * @returns {String}  加密后密码
 */
export const pwdHashEncryptVerify = (password, salt, vCode) => {
  return CryptoJS.SHA256(CryptoJS.SHA256(password + salt).toString() + vCode).toString()
}

export const getPwdRank = (szPwd, szUser = '') => {
  let iRank = 0
  szPwd.match(/[a-z]/g) && iRank++
  szPwd.match(/[A-Z]/g) && iRank++
  szPwd.match(/[0-9]/g) && iRank++
  szPwd.match(/[^a-zA-Z0-9]/g) && iRank++
  iRank = iRank > 3 ? 3 : iRank
  if (
    szPwd.length < 8 ||
        iRank === 1 ||
        szPwd === szUser ||
        szPwd === szUser.split('').reverse().join('')
  ) {
    iRank = 0
  }
  if (iRank === 2) {
    if (
      (szPwd.match(/[0-9]/g) && szPwd.match(/[a-z]/g)) ||
            (szPwd.match(/[0-9]/g) && szPwd.match(/[A-Z]/g))
    ) {
      iRank = 1
    }
  }
  return iRank
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  // eslint-disable-next-line camelcase
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  // eslint-disable-next-line camelcase
  return time_str
}

export const eventBus = new Vue()
