import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import navigation from './modules/navigation'
import indexPage from './modules/indexPage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},

  modules: {
    GLOBAL: global,
    navigation,
    indexPage
  }
})
