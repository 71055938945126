export default [
  {
    path: '/',
    meta: {},
    component: () => import('@/App.vue'),
    redirect: localStorage.getItem('tk') ? '/portal' : '/login'
  },
  {
    path: '/login',
    meta: {},
    component: () => import('@/pages/Login/Login.vue')
  },
  {
    path: '/portal',
    meta: {},
    redirect: 'portal/dashboard',
    component: () => import('@/pages/Framework/Framework.vue'),
    children: [
      {
        path: '/portal/dashboard',
        meta: {},
        component: () => import('@/pages/Dashboard/Dashboard.vue')
      },
      {
        path: '/portal/resetPwd',
        meta: {},
        component: () => import('@/pages/ResetPwd/ResetPwd.vue')
      },
      {
        path: '/portal/updatePwd',
        meta: {},
        component: () => import('@/pages/UpdatePwd/UpdatePwd.vue')
      }
    ]
  },
  { path: '*', component: () => import('@/pages/Error.vue') }
]
