import { http } from './httpInstance'
// import httpNew from './httpNew'

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 11:14:35
* @Desc: 获取数据类型
*/
export function fetchVehicleParams(params) {
  return http.post('pmsccs/common/selections', params)
}

/**
 * @description 获取现金收费统计页面所有数据
 * @author zhengjunling
 * @param {any} params
 * @returns
 */
/**
* @Author: wangqiangrong
* @Date: 2021-04-06 11:15:03
* @Desc: 获取现金收费统计页面所有数据
*/
export function fetchCashStatsPageData(params) {
  return http.post('pmscss/report/charge', params, { noMsg: true })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 14:41:46
* @Desc: 获取停车场树
*/
export function fetchParkTree(params) {
  return http.post('/pmsccs/parking/parkingTree', params)
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:14:30
* @Desc: 获取车流量统计信息
*/
export function fetchVehicleFlowStats(params) {
  return http.post('pmscss/carFlowAction/getCarFlowPictureReport', params, { noMsg: true })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:24:55
* @Desc: 获取出入口列表
*/
export function fetchEntrances(parkId) {
  return http.post('pmsccs/entrance/search', {
    regionId: parkId
  })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:24:34
* @Desc: 获取车道列表
*/
export function fetchRoadways(parkId) {
  return http.post('pmsccs/roadway/search', {
    regionId: parkId
  })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:24:34
* @Desc: 获取停车场概况
*/
export function queryCarParkOverview() {
  return http.get('pmscss/carFlowAction/queryCarParkOverview', {
    params: {},
    noMsg: true
  })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:24:34
* @Desc: 获取余位情况
*/
export function getCarLocations(parkIds) {
  return http.post('pmsccs/parking/slot/get', {
    parkIds
  })
}

/**
 * 获取库内车辆数据
 */
export function inRecordGetCount(params) {
  return http.post('pmsccs/inRecord/getCount', params)
}

/**
 * 获取菜单
 */
export function fetchMenuInfo() {
  // return http.get('menuInfo?type=1')
  return http({
    method: 'get',
    url: 'menuInfo?type=1',
    baseURL: '/webapi/',
  })
}

/**
* @Author: wangqiangrong
* @Date: 2021-04-06 16:24:34
* @Desc: 获取客户端相关信息
*/
export function getClientInfo() {
  return http.get('pmsccs/common/client', {})
}


export function hikyunPlat() {
  return http({
    method: 'get',
    url: 'hikyunPlat',
    baseURL: '/webapi/',
  })
}
