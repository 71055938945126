import axios from 'axios'
import { Message } from 'hui'
import { trimOnlySpace } from '@hui-pro/utils'
import { REQUEST_SUCCESS } from '@/constant'
import i18n from '@/i18n/index.js'

export function createAxios (option, h) {
  option = Object.assign({ successCode: REQUEST_SUCCESS }, option)
  const http = axios.create({
    baseURL: '/webapi/',
    timeout: 20000,
    withCredentials: true,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    ...h
  })

  http.interceptors.request.use(function (config) {
    const tk = localStorage.getItem('tk') || ''
    if (tk) {
      config.headers.Authorization = tk
    }
    return trimOnlySpace(config)
  }, function (error) {
    return Promise.reject(error)
  })

  http.interceptors.response.use(responseInterceptor, responseError)

  function responseInterceptor (response) {
    if (response.data.code !== option.successCode) {
      if (response.data.code === '401') {
        location.href = '/login'
      }
      if (!response.config.noMsg && response.data.msg) {
        Message.error(i18n.t(response.data.msg))
      }
      return Promise.reject(response)
    } else if (response.data.code === option.successCode && response.config.successNotify && response.data.msg) {
      Message.success(i18n.t(response.data.msg))
    }
    return Promise.resolve({
      code: response.data.code,
      msg: response.data.msg,
      data: response.data.data
    })
  }

  function responseError (error) {
    const { status } = error.response

    if (status === 401) {
      location.href = '/login'
    }

    if (error.message.indexOf('timeout') > -1) {
      Message.error('请求超时，请重试！')
    }

    return Promise.reject(error)
  }

  return http
}

export const http = createAxios()

export const eitsHttp = createAxios({ successCode: '200' }, { baseURL: '/webapi/' })
