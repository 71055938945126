import { fetchMenuInfo, hikyunPlat } from '@/api/index'

export default {
  namespaced: true,

  state: {
    tabs: [],
    currentIndex: '',
    yunPlat: ''
  },

  mutations: {
    setTabs (state, playload) {
      state.tabs = playload
    },

    setCurrentIndex (state, playload) {
      state.currentIndex = playload
    },

    setYunPlat (state, data) {
      state.yunPlat = data
    }
  },

  getters: {
    currentTab (state) {
      const find = (menus) => {
        return menus.find(item => {
          if (item.id === state.currentIndex) {
            return true
          } else {
            return !!find(item.children)
          }
        })
      }

      return find(state.tabs) || { id: '0101000000000000' }
    },

    /** 当前的菜单 */
    currentMenu (state, getters) {
      let result
      const find = (menus) => {
        return menus.find(menu => {
          if (menu.id === state.currentIndex) {
            result = menu
            return true
          }
          if (menu.children) return find(menu.children)
          return false
        })
      }
      if (!getters.currentTab.children) {
        return ''
      }
      if (getters.currentTab.id === state.currentIndex) {
        return getters.currentTab
      }
      find(getters.currentTab.children)
      return result
    },

    /** 当前的面包屑 */
    currentBreadcrumb (state) {
      const path = []
      const walk = (tabs, p) => {
        for (const tab of tabs) {
          if (state.currentIndex === tab.key) {
            p.push(tab.text)
            return true
          }
          if (tab.menus) {
            p.push(tab.text)
            if (walk(tab.menus, p)) {
              return true
            } else {
              p.pop()
              continue
            }
          }
        }
      }
      walk(state.tabs, path)
      return path
    }
  },

  actions: {
    async fetchMenuInfo ({ commit }) {
      const { data } = await fetchMenuInfo()
      commit('setTabs', data)
    },

    async fetchYunPlat ({ commit }) {
      const { data } = await hikyunPlat()
      commit('setYunPlat', data)
    }
  }
}
