// export const allList = ['tckgl', 'clgl', 'fxgl', 'sfgl', 'xsbb', 'tjfx', 'shyh', 'jlcx', 'sbgl', 'ywjz'] // 所有的快捷入口简称

export const defaultList = ['tckgl', 'clgl', 'fxgl', 'sfgl', 'xsbb', 'tjfx', 'shyh', 'jlcx', 'sbgl']

export const shortMap = {
  tckgl: {
    text: '停车场管理', // 快捷入口名称
    imageActive: require('@/assets/widget/tck_blue.svg'), // 快捷入口高亮图片
    imageNormal: require('@/assets/widget/tck_white.svg'), // 快捷入口白色背景图片
    tab: '0106010000000000', // 快捷入口页面对应的tab
    // url: '/pms/park', // 快捷入口对应的pms页面的地址
    name: 'tckgl'
  },
  clgl: {
    text: '车辆管理',
    imageActive: require('@/assets/widget/cheliang_blue.svg'),
    imageNormal: require('@/assets/widget/cheliang_white.svg'),
    tab: '0102010000000000',
    // url: '/pms/application/vehicle/vehicle',
    name: 'clgl'
  },
  fxgl: {
    text: '放行管理',
    imageActive: require('@/assets/widget/fangxing_blue.svg'),
    imageNormal: require('@/assets/widget/fangxing_white.svg'),
    tab: '0103010000000000',
    // url: '/pms/application/release/carRelease',
    name: 'fxgl'
  },
  sfgl: {
    text: '收费管理',
    imageActive: require('@/assets/widget/shoufei_blue.svg'),
    imageNormal: require('@/assets/widget/shoufei_white.svg'),
    tab: '0103020000000000',
    // url: '/pms/application/charge/chargeRuleListTemp',
    name: 'sfgl'
  },
  xsbb: {
    text: '显示播报',
    imageActive: require('@/assets/widget/xsbb_blue.svg'),
    imageNormal: require('@/assets/widget/xsbb_white.svg'),
    tab: '0103030100000000',
    // url: '/pms/application/ledvoice/led',
    name: 'xsbb'
  },
  tjfx: {
    text: '车流量统计',
    imageActive: require('@/assets/widget/tjfx_blue.svg'),
    imageNormal: require('@/assets/widget/tjfx_white.svg'),
    tab: '0103050000000000',
    // url: '/pms/application/stats/vehicleFlow',
    name: 'tjfx'
  },
  // cwgl: {
  //   text: '车位管理',
  //   imageActive: require('@/assets/widget/chewei_blue.svg'),
  //   imageNormal: require('@/assets/widget/chewei_white.svg'),
  //   tab: '',
  //   url: '',
  //   name: 'cwgl'
  // },
  // ggfb: {
  //   text: '广告发布',
  //   imageActive: require('@/assets/widget/guanggao_blue.svg'),
  //   imageNormal: require('@/assets/widget/guanggao_white.svg'),
  //   tab: '',
  //   url: '',
  //   name: 'ggfb'
  // },
  shyh: {
    text: '商户优惠',
    imageActive: require('@/assets/widget/shyh_blue.svg'),
    imageNormal: require('@/assets/widget/shyh_white.svg'),
    tab: '0104020000000000',
    // url: '/pms/application/merchant/couponManage',
    name: 'shyh'
  },
  // spxc: {
  //   text: '视频巡查',
  //   imageActive: require('@/assets/widget/spxc_blue.svg'),
  //   imageNormal: require('@/assets/widget/spxc_white.svg'),
  //   tab: '',
  //   url: '',
  //   name: 'spxc'
  // },
  jlcx: {
    text: '记录查询',
    imageActive: require('@/assets/widget/jlcx_blue.svg'),
    imageNormal: require('@/assets/widget/jlcx_white.svg'),
    tab: '0103040000000000',
    // url: '/pms/application/record/',
    name: 'jlcx'
  },
  sbgl: {
    text: '设备管理',
    imageActive: require('@/assets/widget/sbgl_blue.svg'),
    imageNormal: require('@/assets/widget/sbgl_white.svg'),
    tab: '0106020000000000',
    // url: '/pms/application/deviceManage',
    name: 'sbgl'
  },
  ywjz: {
    text: '余位校正',
    imageActive: require('@/assets/widget/ywjz_blue.svg'),
    imageNormal: require('@/assets/widget/ywjz_white.svg'),
    tab: '0103060000000000',
    // url: '/pms/application/parkSpaceConfig',
    name: 'ywjz'
  }
  // czfw: {
  //   text: '车主服务',
  //   imageActive: require('@/assets/widget/czfw_blue.svg'),
  //   imageNormal: require('@/assets/widget/czfw_white.svg'),
  //   tab: '',
  //   url: '',
  //   name: 'czfw'
  // }
}
