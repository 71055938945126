import Vue from 'vue'
import Router from 'vue-router'
import routers from '@/router.config'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_CONTEXT,
  routes: routers
})

export default router
