<template>
    <div class="pwd-level">
        <div class="level-process">
            <span v-for="(item, index) in 4" class="level-process-block" :style="{background:`${index>level?`#EDEFF4`:styleColor[level]}`}" :key="index"></span>
        </div>
        <div class="level-tip" :style="{color:tipsColor[level]}">{{tipsText[level]}}</div>
    </div>
</template>

<script>
import { getPwdRank } from '@/utils/util.js'
export default {
  name: 'eits-pwd-level',
  data () {
    return {
      tipsColor: ['#FE5332', '#FF952C', '#FFCC00', '#3BCD8D'],
      tipsText: ['危险', '弱', '中', '强'],
      styles: ['danger', 'warning', 'proper', 'strong'],
      styleColor: ['#FE5332', '#FF952C', '#FFCC00', '#3BCD8D']
    }
  },
  props: {
    pwd: {
      type: String,
      default: ''
    }
  },
  computed: {
    level () {
      return getPwdRank(this.pwd)
    }
  }
}

</script>

<style lang="scss" scope>

.pwd-level {
    position: relative;
    height: 20px;
    line-height: 20px;
    width: calc(100%);
    display: flex;
    justify-content: space-between;
    .level-process {
        position: relative;
        width: calc(100% - 32px);
        height: 4px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        span.level-process-block {
            position: relative;
            height: 4px;
            margin-right: 3px;
            background: #EDEFF4;
            flex: 1;
        }
    }
    .level-tip {
        position:relative;
        width: 25px;
        text-align: center;
        font-size: 12px;
    }
}

</style>
