<template>
    <div class="eits-login-pwd-dialog">
        <el-dialog
        :title="dialogTitle"
        :visible="showDialog"
        :before-close="closeDialog"
        :append-to-body="false"
        :close-on-click-modal="false"
        :area="480"
        class="modifypassword-main"
        >
            <div class="dialog_style__one">
                <el-popover ref="popover1" trigger="manual" placement="top" width="400" :value="showNewPwdTip">
                    <div class="pwd-rule">
                        <svg v-if="!pwdLackOfLen" t="1571050280880" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5360" width="16" height="16"><path d="M843.693959 293.609061 425.255869 712.056362 186.145026 472.947566 66.579883 592.504522 425.255869 951.165158 963.260126 413.174204Z" p-id="5361" fill="#02BF0F"></path></svg>
                        <svg v-else t="1571050217123" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4414" width="16" height="16"><path d="M832 742.4 601.6 512 825.6 288 736 198.4 512 422.4 281.6 192 192 281.6 422.4 512 198.4 736 288 825.6 512 601.6 742.4 832Z" p-id="4415" fill="#FA3239"></path></svg>
                        <span>{{PWD_SIZE_RULE}}</span>
                    </div>
                    <div class="pwd-rule">
                        <svg v-if="!pwdLackOfType" t="1571050280880" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5360" width="16" height="16"><path d="M843.693959 293.609061 425.255869 712.056362 186.145026 472.947566 66.579883 592.504522 425.255869 951.165158 963.260126 413.174204Z" p-id="5361" fill="#02BF0F"></path></svg>
                        <svg v-else t="1571050217123" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4414" width="16" height="16"><path d="M832 742.4 601.6 512 825.6 288 736 198.4 512 422.4 281.6 192 192 281.6 422.4 512 198.4 736 288 825.6 512 601.6 742.4 832Z" p-id="4415" fill="#FA3239"></path></svg>
                        <span>{{PWD_TYPE_RULE}}</span>
                    </div>
                </el-popover>
                <el-popover popper-class="newpwd-tip" ref="popover2" trigger="manual" placement="top" width="400" :value="showNamePwdTip">
                    <div class="pwd-rule">
                        <svg t="1571050217123" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4414" width="16" height="16"><path d="M832 742.4 601.6 512 825.6 288 736 198.4 512 422.4 281.6 192 192 281.6 422.4 512 198.4 736 288 825.6 512 601.6 742.4 832Z" p-id="4415" fill="#FA3239"></path></svg>
                        <span>密码不能是用户名或者用户名的倒写</span>
                    </div>
                </el-popover>
                <el-form :model="form" ref="form" :rules="rules" v-if="changePwd" label-position="top">
                    <el-form-item :style="{'margin-bottom':`${showPwdStrenth?'4px':''}`}" prop="password" label="新密码">
                        <el-input
                            :type="isShowPwd ? 'text' : 'password'"
                            v-model="form.password"
                            placeholder="请输入新密码"
                            @focus="checkNewPwd"
                            @change="checkNewPwd"
                            v-popover:popover1
                            v-popover:popover2>
                            <i
                                slot="suffix"
                                class="el-input__icon"
                                :class="isShowPwd ? 'h-icon-password_visible' : 'h-icon-password_unvisible'"
                                @mousedown="isShowPwd=!isShowPwd"
                                @mouseup="isShowPwd=!isShowPwd">
                            </i>
                        </el-input>
                        <eits-pwd-level :pwd="form.password" v-show="form.password"></eits-pwd-level>
                    </el-form-item>
                    <el-form-item prop="confirm" label="重复新密码">
                            <el-input
                            :type="isShowPwd2 ? 'text' : 'password'"
                            v-model="form.confirm"
                            placeholder="请再次输入新密码"
                            @focus="checkNewPwd"
                            @change="checkNewPwd"
                            >
                            <i
                                slot="suffix"
                                class="el-input__icon"
                                :class="isShowPwd2 ? 'h-icon-password_visible' : 'h-icon-password_unvisible'"
                                @mousedown="isShowPwd2=!isShowPwd2"
                                @mouseup="isShowPwd2=!isShowPwd2">
                            </i>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form v-if="changePhone" ref="phoneNoForm" :model="phoneNoForm" label-position="top" :rules="rules">
                    <el-form-item  label="手机号" prop="phoneNo">
                        <el-input v-model="phoneNoForm.phoneNo" placeholder="请输入手机号">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form v-if="changePhone" ref="verifyCodeForm" :model="verifyCodeForm" label-position="top" :rules="rules">
                    <el-form-item label="验证码" prop="verifyCode">
                        <div class="verify-code">
                            <el-input v-model="verifyCodeForm.verifyCode" placeholder="请输入验证码"></el-input>
                            <el-button type="primary" @click="sendCode" :disabled="btnFlag">{{btnTxt}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="confirm" :loading="loading">确 定</el-button>
                <el-button @click="closeDialog">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { PWD_IS_EMPTY, PWD_SIZE_RULE, PWD_TYPE_RULE, CONFIRM_IS_EMPTY, NOT_EQUAL_ERROR, PWD_CHAR_ERROR } from './constant.js'
import axios from 'axios'
import { pwdAesEncrypt, pwdHashEncryptSave } from '@/utils/util.js'

export default {
  name: 'eits-login-pwd-dialog',
  props: {
    /**
         * [changePwd 只显示修改密码]
         * @type Boolean
         */
    changePwd: {
      type: Boolean,
      default: false
    },
    /**
         * [changePhone 只显示强制验证手机号]
         * @type Boolean
         */
    changePhone: {
      type: Boolean,
      default: false
    },
    /**
         * [dialogTitle 弹框标题]
         * @type String
         */
    dialogTitle: {
      type: String,
      default: ''
    },
    /**
         * [showDialog 控制弹框显示]
         * @type Boolean
         */
    showDialog: {
      type: Boolean,
      default: false
    },
    /**
         * [token token]
         * @type String
         */
    token: {
      type: String,
      default: ''
    },
    /**
         * [user 用户名]
         * @type String
         */
    user: {
      type: String,
      default: ''
    },
    /**
         * [productCode 产品标识]
         * @type {Object}
         */
    productCode: {
      type: String,
      required: true
    },
    /**
         * [type 用户类型，默认1]
         * @type {Object}
         */
    type: {
      type: Number,
      default: 1
    },
    /**
         * [challengeCodeUrl 获取挑战码接口]
         * @type {String}
         */
    challengeCodeUrl: {
      type: String,
      default: '/login/web/challengeCode'
    },
    /**
         * [changeWeakPwdUrl 修改密码接口]
         * @type {Object}
         */
    changeWeakPwdUrl: {
      type: String,
      default: '/ybase/web/user/pwd/update'
    },
    /**
         * [phoneNoVerifyUrl 手机认证状态接口]
         * @type {Object}
         */
    phoneNoVerifyUrl: {
      type: String,
      default: '/eits/login/phone/status'
    },
    /**
         * [getVerifyUrl 获取验证码接口]
         * @type {Object}
         */
    getVerifyUrl: {
      type: String,
      default: '/eits/login/user/phone/verifyCode'
    },
    /**
         * [checkVerifyUrl 校验验证码接口]
         * @type {Object}
         */
    checkVerifyUrl: {
      type: String,
      default: '/eits/login/phone/checkCode'
    }
  },
  data () {
    const validateNewPass = (rule, value, callback) => {
      // eslint-disable-next-line no-control-regex
      if (value.match(/[^\x00-\xff]/)) {
        callback(PWD_CHAR_ERROR)
      } else {
        callback()
      }
    }
    const validateConfirm = (rule, value, callback) => {
      value === this.form.password ? callback() : callback(NOT_EQUAL_ERROR)
    }
    const checkPhoneNo = (rule, value, callback) => {
      if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号码格式有误'))
      } else {
        callback()
      }
    }
    const checkPhoneUnique = async (rule, value, callback) => {
      const res = await this.checkphoneNoFn(value)
      if (res.data.code === '1007') {
        callback()
      } else if (res.data.code === '200') {
        callback(new Error('该手机号已被注册'))
      } else {
        callback(new Error('手机号验证失败'))
      }
    }
    return {
      form: {
        password: '',
        confirm: ''
      },
      phoneNoForm: {
        phoneNo: ''
      },
      verifyCodeForm: {
        verifyCode: ''
      },
      btnTxt: '发送验证码',
      btnFlag: false,
      loading: false,
      interval: '',
      rules: {
        password: [
          { required: true, message: PWD_IS_EMPTY, trigger: 'blur + change' },
          { validator: validateNewPass, trigger: 'change + blur' }
        ],
        confirm: [
          { required: true, message: CONFIRM_IS_EMPTY, trigger: 'blur + change' },
          { validator: validateConfirm, trigger: 'change+blur' }
        ],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'change+blur' },
          { validator: checkPhoneNo, trigger: 'blur' },
          { validator: checkPhoneUnique, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      isShowPwd: false,
      isShowPwd2: false,
      pwdLackOfLen: false,
      pwdLackOfType: false,
      pwdOverOfType: false, // 字符种类超过
      PWD_SIZE_RULE: PWD_SIZE_RULE,
      PWD_TYPE_RULE: PWD_TYPE_RULE,
      PWD_CHAR_ERROR: PWD_CHAR_ERROR
    }
  },
  computed: {
    showNewPwdTip () { // 密码规则不符合
      return (this.pwdLackOfLen || this.pwdLackOfType) && !this.showNamePwdTip
    },
    showPwdStrenth () {
      return this.form.password && !this.pwdOverOfType
    },
    showNamePwdTip () { // 密码和用户名相似
      const { password } = this.form
      if (password) {
        return false || password === this.user || password === this.user.split('').reverse().join('')
      } else {
        return false
      }
    }
  },
  watch: {
    dialogType: {
      handler (val) {
        console.log(val)
        if (val === '1') {
          this.showDialog = true
          this.changePwd = true
          this.dialogTitle = '修改密码'
        } else if (val === '2') {
          this.showDialog = true
          this.changePhone = true
          this.dialogTitle = '验证手机号'
        } else if (val === '3') {
          this.showDialog = true
          this.changePwd = true
          this.changePhone = true
          this.dialogTitle = '修改密码'
        }
      },
      immediate: true
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
      this.btnTxt = '发送验证码'
      this.form.password = ''
      this.form.confirm = ''
      this.phoneNoForm.phoneNo = ''
      this.verifyCodeForm.verifyCode = ''
      this.$refs.form && this.$refs.form.resetValidates()
      this.$refs.phoneNoForm && this.$refs.phoneNoForm.resetValidates()
      this.$refs.verifyCodeForm && this.$refs.verifyCodeForm.resetValidates()
    },
    checkphoneNoFn () {
      return axios({
        url: this.phoneNoVerifyUrl,
        method: 'post',
        data: {
          phoneNo: this.phoneNoForm.phoneNo,
          productCode: this.productCode,
          type: this.type
        },
        headers: {
          Authorization: this.token
        }
      })
    },
    confirm () {
      if (this.changePwd && this.changePhone) {
        this.updatePhoneNoAndPwd()
      } else if (!this.changePwd && this.changePhone) {
        this.updatePhoneNo()
      } else if (this.changePwd && !this.changePhone) {
        this.beforeReset()
      }
    },
    /**
         * 同时修改密码和手机号
         */
    updatePhoneNoAndPwd () {
      const { showNewPwdTip, showNamePwdTip } = this
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$refs.phoneNoForm.validate((valid) => {
            if (!valid) {
              this.$refs.verifyCodeForm.validate()
            }
          })
          return false
        }
        if (showNewPwdTip || showNamePwdTip) {
          return this.$message({ message: '请输入符合规则的密码', type: 'warning' })
        }
        this.$refs.phoneNoForm.validate((valid) => {
          if (!valid) {
            this.$refs.verifyCodeForm.validate()
            return false
          }
          this.$refs.verifyCodeForm.validate(async (valid) => {
            if (!valid) return false
            this.loading = true
            const challengeRes = await this.getChallengeCode()
            const res = await axios({
              method: 'post',
              url: this.checkVerifyUrl,
              data: {
                codeId: challengeRes.data.data.codeId,
                phoneNo: this.phoneNoForm.phoneNo,
                verifyCode: this.verifyCodeForm.verifyCode
              },
              headers: {
                Authorization: this.token
              }
            })
            if (res.data.code === '200') {
              this.beforeReset()
            } else {
              this.$message.error(res.data.msg)
              this.loading = false
              return false
            }
          })
        })
      })
    },
    /**
         * 强制认证手机号
         */
    updatePhoneNo () {
      this.$refs.verifyCodeForm.validate(async (valid) => {
        if (!valid) return false
        this.loading = false
        const calRes = await this.getChallengeCode()
        return axios({
          method: 'post',
          url: this.checkVerifyUrl,
          data: {
            codeId: calRes.data.data.codeId,
            phoneNo: this.phoneNoForm.phoneNo,
            verifyCode: this.verifyCodeForm.verifyCode
          },
          headers: {
            Authorization: this.token
          }
        }).then((res) => {
          if (res.data.code === '200') {
            this.checkBack(res.data)
            this.$message.success('手机号认证成功')
            this.loading = false
            this.closeDialog()
          }
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      })
    },
    sendCode () {
      this.$refs.phoneNoForm.validate(async (valid) => {
        if (!valid) return false
        this.handleTimer()
        return axios({
          method: 'post',
          url: this.getVerifyUrl,
          data: {
            phoneNo: this.phoneNoForm.phoneNo,
            productCode: this.productCode,
            type: this.type
          },
          headers: {
            Authorization: this.token
          }
        }).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('手机验证码发送成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },
    handleTimer () {
      let num = 60
      const interval = setInterval(() => {
        this.interval = interval
        num--
        this.btnTxt = num + '秒后重新获取'
        this.btnFlag = true
        if (num <= 1) {
          this.btnFlag = false
          num = 60
          this.btnTxt = '重新获取验证码'
          clearInterval(interval)
        }
      }, 1000)
    },
    /**
         * 分条检验密码要求
         */
    checkNewPwd () {
      const pwd = this.form.password
      this.pwdLackOfLen = pwd.length < 8 || pwd.length > 20
      // 字符种类校验
      // eslint-disable-next-line no-control-regex
      this.pwdOverOfType = !!pwd.match(/[^\x00-\xff]/)
      let iRank = 0
      pwd.match(/[a-z]/g) && iRank++
      pwd.match(/[A-Z]/g) && iRank++
      pwd.match(/[0-9]/g) && iRank++
      pwd.match(/[^a-zA-Z0-9]/g) && iRank++
      this.pwdLackOfType = iRank < 2
    },
    beforeReset () {
      if (this.showNewPwdTip || this.showNamePwdTip) {
        return this.$message({ message: '请输入符合规则的密码', type: 'warning' })
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          const res = await this.getChallengeCode()
          this.loading = !!(res.data.code === '200')
          res.data.code === '200' ? this.afterChangePwd(await this.changePwdFn(res.data.data)) : this.$message.error(res.data.msg)
        }
      })
    },
    getChallengeCode () {
      return axios({
        url: this.challengeCodeUrl,
        method: 'post',
        data: {
          name: this.user,
          productCode: this.productCode,
          type: this.type
        }
      })
    },
    /**
         * 弱密码或者首次登陆的修改密码
         */
    changePwdFn (params) {
      return axios({
        url: this.changeWeakPwdUrl,
        method: 'post',
        data: {
          codeId: params.codeId,
          newPwd: this.encryptPwd(this.form.password, params.salt, params.vCode),
          salt: params.salt
        },
        headers: { authorization: this.token }
      })
    },
    encryptPwd (pwd, salt, vCode) {
      return pwdAesEncrypt(pwdHashEncryptSave(pwd, salt), vCode, salt)
    },
    afterChangePwd (res) {
      this.loading = false
      this.closeDialog()
      res.data.code === '200' ? this.checkBack(res.data) : this.$message.error(res.data.msg)
    },
    /** ***********对外事件VIP*************/
    checkBack (payload) {
      this.$emit('check-back', payload)
    }
    /**********************************/
  }
}

</script>

<style lang="scss" scoped>
    .eits-login-pwd-dialog {
        position: relative;
        height: calc(100%);
        width: calc(100%);
        min-width: 160px;
        text-align: left;
        .modifypassword-main .verify-code .el-input {
            width: 175px;
        }
        .modifypassword-main .verify-code .el-button {
            width: 140px;
        }
        .dialog_style__one {
            padding: 20px 68px 36px;
        }
    }
    .pwd-rule {
        svg, span {
            vertical-align: middle;
        }
    }
</style>
