
export default {
  namespaced: true,

  state: {
    session: {
      Authorization: localStorage.getItem('tk')
    },
    userInfo: {}
  },

  mutations: {
    setSession (state, playload) {
      Object.assign(state.session, playload)
      localStorage.setItem('tk', playload.Authorization)
    },

    setUserInfo (state, playload) {
      state.userInfo = playload
    }
  }
}
